import { HandWritingH1 } from "../UI/hand-writing/HandWritingH1";
import { AutoCarousel } from "./homepage/carousel/Carousel";
import { isPhone } from "../../constants/env";
import { useTranslation } from "next-i18next";
import { Grid } from "@mantine/core";

export function HeroSection() {
  const { t } = useTranslation("common");

  return (
    <>
      <Grid.Col
        style={{ minHeight: isPhone ? "35vh" : "100px" }}
        span={{ base: 12, lg: 6 }}
        className="flex items-center justify-center"
      >
        <HandWritingH1 size={isPhone ? 50 : 60}>{t("h1")}</HandWritingH1>
      </Grid.Col>
      <Grid.Col
        style={{ minHeight: isPhone ? "35vh" : "100px" }}
        span={{ base: 12, lg: 6 }}
        className="flex items-center justify-center"
      >
        <AutoCarousel />
      </Grid.Col>
      <Grid.Col
        style={{ minHeight: isPhone ? "35vh" : "100px" }}
        span={12}
        className="flex items-center justify-center mt-6 text-center"
      >
        <span
          // @ts-ignore
          dangerouslySetInnerHTML={{ __html: t("psiHtml") }}
          style={{ fontSize: 40 }}
        />
      </Grid.Col>
    </>
  );
}
