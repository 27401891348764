import { Fragment, useRef } from "react";
import Autoplay from "embla-carousel-autoplay";
import { Carousel } from "@mantine/carousel";
import { useWindowSize } from "../../../../hooks/misc/useWindowSize";
import Image from "next/image";
import { mobile, tablet } from "../../../../constants/env";

const images = [
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F1.webp?alt=media&token=d6915b88-c2e5-4a1f-8516-575663d3fb96",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F2.webp?alt=media&token=0f9aec27-c2e2-4b89-b478-892f1223f3f4",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F3.webp?alt=media&token=f3862f79-28d6-43d9-b150-d4c3ff98919e",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F4.webp?alt=media&token=5eb69fde-dec9-4623-8a6b-ba47299c3ff2",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F5.webp?alt=media&token=14123d67-ac82-4a68-ad03-76eefc0b7408",
  // "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F6.webp?alt=media&token=69ec3e3f-897a-405c-8db3-df1d6d33de8f",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F7.webp?alt=media&token=5c30f16d-e14f-473b-ac2e-49a4c9a6d1e3",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F8.webp?alt=media&token=646c3655-dfd4-4c72-a04e-3ec6506ab1dc",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F9.webp?alt=media&token=99f06ff8-4483-436c-961f-36900866a771",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F10.webp?alt=media&token=d028c9a1-dc39-410a-a83d-81d651c1f8f3",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F11.webp?alt=media&token=a9b00da6-2099-4b1f-81c7-6d186722a8cb",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F12.webp?alt=media&token=b4d2e41d-aff3-431e-8727-fa84f7003224",
  // "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F13.webp?alt=media&token=86f802e1-f8b8-4a60-a67f-eeff9061153f",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F14.webp?alt=media&token=8e89dc5c-0c84-4c74-b231-0b7a340658ab",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F15.webp?alt=media&token=08fa005c-f434-46d2-8ff2-b98806e3f89b",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F16.webp?alt=media&token=0b5baac0-050b-4161-b743-2b8afba4df7a",
  "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F17.webp?alt=media&token=4133942b-45f9-45ce-b9f5-aa23d4a125b8",
  // "https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/images%2F18.webp?alt=media&token=a6a973ba-79a8-411f-a285-0d8b53eb004d",
];

export function AutoCarousel() {
  const autoplay = useRef(Autoplay({ delay: 3000 }));
  const { windowSize } = useWindowSize();

  function setWidth(width: number): number {
    function desktopWidth(): number {
      if (width > 1600) {
        return 800;
      } else {
        return width * 0.48;
      }
    }

    if (width > 1124) {
      return desktopWidth();
    } else {
      return width - 32;
    }
  }

  let slideClass = "relative w-[320px] h-[180px]";
  if (!mobile) slideClass = "relative w-[480px] h-[360px]";
  if (tablet) slideClass = "relative w-[480px] h-[270px]";

  return (
    <Fragment>
      {windowSize.width && (
        <Carousel
          style={{
            width: setWidth(windowSize.width),
            marginTop: !mobile ? "2rem" : undefined,
          }}
          mx="auto"
          loop
          // withIndicators
          withControls={false}
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
        >
          {images.map((link, index) => {
            return (
              <Carousel.Slide key={index} className={slideClass}>
                <Image
                  priority
                  alt="Person"
                  src={link}
                  sizes="320px"
                  fill
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Carousel.Slide>
            );
          })}
        </Carousel>
      )}
    </Fragment>
  );
}
