import { useColors } from "../../../../hooks/colors/useColors";
import { Card } from "@mantine/core";
import { HandWritingH1 } from "../../../UI/hand-writing/HandWritingH1";
import { MyH1 } from "../../../UI/my-h1/MyH1";
import Image from "next/image";
import { SpecialistDataProps } from "../../../../hooks/react-query/useSpecialist";
import { ButtonLink } from "../../../UI/button-link/ButtonLink";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { isPhone, mobile } from "../../../../constants/env";
import { isTablet } from "react-device-detect";
import { HandWritingH2 } from "../../../UI/hand-writing/HandWritingH2";

export function SpecialistCard(props: SpecialistDataProps) {
  const { colors } = useColors();
  const { t } = useTranslation("common");

  function width() {
    if (isPhone) {
      return "96vw";
    } else if (isTablet) {
      return 360;
    } else {
      return 400;
    }
  }

  const { specialist } = props;
  return (
    // <Link
    //   href={`/especialistas/${specialist.urlSlug}`}
    //   className="hover:cursor-pointer"
    // >
    <Card
      radius="xl"
      shadow="xl"
      className="!p-0 relative my-6 hover:cursor-pointer hover:shadow-2xl"
      style={{ width: width() }}
    >
      <div className="flex-col items-center justify-center gap-2">
        <div className="relative" style={{ aspectRatio: "55/75" }}>
          <Image
            src={specialist.image}
            layout="fill"
            alt={specialist.name}
            priority={!mobile}
            sizes="1152px 480px 320px"
            style={{
              objectFit: "cover",
            }}
          />
        </div>
        <HandWritingH2 size={30} className="p-4" color={colors.primary}>
          {specialist.name}
        </HandWritingH2>
      </div>
      <div className="flex flex-col items-center min-h-[80px] justify-center">
        <h3 className="text-center font-bold text-2xl p-2 my-0">
          {specialist.description}
        </h3>
      </div>
      <div className="flex items-center justify-center my-6 w-full">
        <ButtonLink
          href={`/especialistas/${specialist.urlSlug}`}
          color={colors.secondary}
          title={t("bio")}
          rounded
        />
      </div>
    </Card>
    // </Link>
  );
}
