import { useColors } from "../../../../hooks/colors/useColors";
import { Card, Text } from "@mantine/core";
import classes from "./specialties.module.css";
import Image from "next/image";
import { ButtonLink } from "../../../UI/button-link/ButtonLink";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { isPhone } from "../../../../constants/env";

type InputProps = {
  href: string;
  image: string;
  icon?: string;
  title: string;
  description: string;
  iconWidth?: number;
};

export function SpecialtyCard(props: InputProps) {
  const { colors } = useColors();
  const { t } = useTranslation("common");

  const { image, icon, title, description, iconWidth, href } = props;
  return (
    // <Link href={href}>
    <Card
      radius="xl"
      withBorder
      style={{ maxWidth: isPhone ? 360 : 400, zIndex: 0 }}
      className="my-6 hover:cursor-pointer hover:shadow-2xl"
    >
      <div className="flex-col items-center justify-center gap-2 mb-6">
        {icon && (
          <div className="flex items-center justify-center ">
            <Image
              src={icon}
              height={85}
              width={iconWidth || 85}
              alt={title}

              // objectFit="cover"
            />
          </div>
        )}
        <h1
          className="text-center"
          style={{
            color: colors.secondary,
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          {title}
        </h1>
      </div>
      <Card.Section>
        <Image src={image} height={160} alt={title} width={400} />
      </Card.Section>
      <div className="px-2 py-6">
        <span
          className={`${classes.description} text-xl`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="flex items-center justify-center ">
        <ButtonLink
          href={href}
          color={colors.secondary}
          title={t("read")}
          rounded
        />
      </div>
    </Card>
    // </Link>
  );
}
