import { create } from "zustand";

interface MobileSite {
  isMobileSite: boolean;
  setMobileSite: (value: boolean) => void;
}

export const useMobileSite = create<MobileSite>()((set) => ({
  isMobileSite: false,
  setMobileSite: (value: boolean) =>
    set((state) => ({
      isMobileSite: value,
    })),
}));
