import { useColors } from "../../../../hooks/colors/useColors";
import { Container } from "../../../UI/container/Container";
import { Grid } from "@mantine/core";
import { HandWritingH1 } from "../../../UI/hand-writing/HandWritingH1";
import { SpecialistCard } from "./SpecialistCard";
import { MyButton } from "../../../UI/my-button/MyButton";
import { mobile } from "../../../../constants/env";
import {
  SpecialistData,
  useSpecialistList,
} from "../../../../hooks/react-query/useSpecialistList";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Fragment } from "react";
import { ButtonLink } from "../../../UI/button-link/ButtonLink";

export function Specialists() {
  const { colors } = useColors();
  const { specialists } = useSpecialistList();
  const { t } = useTranslation("common");
  return (
    <>
      <Container noHeight>
        <Grid>
          <Grid.Col span={12} className="flex items-center justify-center py-6">
            <HandWritingH1 size={mobile ? 40 : 60}>
              {t("specialists-title")}
            </HandWritingH1>
          </Grid.Col>
          <>
            {specialists && (
              <Fragment>
                {Object.keys(specialists)
                  .slice(0, 6)
                  .map((key) => {
                    const specialist: SpecialistData = specialists[key];
                    return (
                      <Grid.Col
                        key={key}
                        span={{ base: 12, md: 6, lg: 4 }}
                        className="flex justify-center"
                      >
                        <SpecialistCard specialist={specialist} />
                      </Grid.Col>
                    );
                  })}
              </Fragment>
            )}
          </>
          <Grid.Col span={12} className="flex justify-center !pb-8">
            <ButtonLink
              rounded
              href="/especialistas"
              title={t("other-spec")}
              color={colors.primary}
              textColor="white"
            />
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
}
