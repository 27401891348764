import { create } from "zustand";

interface PaginationState {
  hasNextPage: boolean;
  setHasNextPage: (value: boolean) => void;
  after?: string;
  setAfter: (value: string | undefined) => void;
}

export const usePaginationStore = create<PaginationState>()((set) => ({
  hasNextPage: false,
  hasPreviousPage: false,
  setHasNextPage: (value: boolean) =>
    set((state) => ({
      hasNextPage: value,
    })),
  setAfter: (value: string | undefined) =>
    set((state) => ({
      after: value,
    })),
}));
