import type { NextPage } from "next";
import { Grid } from "@mantine/core";
import { HeroCards } from "../components/pages/homepage/hero-card/HeroCards";
import { Specialties } from "../components/pages/homepage/specialties/Specialties";
import { HeroSection } from "../components/pages/HeroSection";
import { Container } from "../components/UI/container/Container";
import { Specialists } from "../components/pages/homepage/specialists/Specialists";
import { Assessment } from "../components/pages/homepage/assessment/Assessment";
import { BlogSection } from "../components/pages/homepage/blog/BlogSection";
import { MyHead } from "../components/pages/MyHead";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { Fragment } from "react";
import { useMobileSite } from "../components/config/store/useMobileSite";
import Script from "next/script";
import { isPhone } from "../constants/env";
import { Footer } from "../components/pages/homepage/footer/Footer";

// @ts-ignore
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      // Will be passed to the page component as props
    },
  };
}

const Home: NextPage = () => {
  const { i18n } = useTranslation("common");
  const { isMobileSite } = useMobileSite((state) => state);

  return (
    <Fragment>
      <MyHead title={i18n.t("psiTitle")} description={i18n.t("psi")} />
      <Container noHeight>
        <Grid>
          <HeroSection />
          <HeroCards />
        </Grid>
      </Container>
      {!isMobileSite && <Specialties />}
      {!isMobileSite && <Specialists />}
      {!isMobileSite && <Assessment />}
      {!isMobileSite && <BlogSection />}
      <div className="mb-8">
        <Container noHeight>
          <div>
            <Script src="https://fbl.embedwidgets.com/c7Fxea4m5Ah5ufk3SEKZ.js" />
            <Script src="https://feedback.link/web_widget/feedbackbutton?id=H6sNIMqhCuNihdwc2jD8keUVsBQsTv9n2NErEhDP4dwC1719613561" />
            <Script id="feedback-button">
              {`
                         //Feedback Link Feedback Button:
            ! function(w, d){
              if (!w || !d) return false
              w.fbl_2020_Widget_Config = {
                  position: 'left',
                  color: 'light',
                  link: 'https://feedback.link/psiworks',
                  brandHex: '#5AAAF9',
                  frequency: 'a'
              };
              d.addEventListener("DOMContentLoaded", function(event) {
                  var s = d.createElement('script');
                  s.setAttribute('src','https://feedback.link/web_widget/feedbackbutton?id=H6sNIMqhCuNihdwc2jD8keUVsBQsTv9n2NErEhDP4dwC1719613561');
                  d.body.appendChild(s);
              });
              }(window, document)
                        `}
            </Script>
            <div className="fbl_w_c7Fxea4m5Ah5ufk3SEKZ !mb-8"></div>
          </div>
        </Container>
      </div>
      {!isPhone && <Footer isVisible delay />}
    </Fragment>
  );
};

export default Home;
