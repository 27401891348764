import { ReactNode } from "react";

type InputProps = {
  children: ReactNode;
  size?: number;
  color?: string;
  className?: string;
};

export function HandWritingH2(props: InputProps) {
  const { size, children, color, className } = props;
  return (
    <h2
      className={className ? className + " text-center" : "text-center"}
      style={{
        fontFamily: "HandWriting",
        fontSize: size,
        color: color,
        margin: 0,
      }}
    >
      {children}
    </h2>
  );
}
