import { useColors } from "../../../../hooks/colors/useColors";
import { Container } from "../../../UI/container/Container";
import { Checkbox, Grid, Text, Textarea, TextInput } from "@mantine/core";
import { HandWritingH1 } from "../../../UI/hand-writing/HandWritingH1";
import Link from "next/link";
import { MyButton } from "../../../UI/my-button/MyButton";
import { MyH1 } from "../../../UI/my-h1/MyH1";
import { useTranslation } from "next-i18next";
import { useAssessment } from "./useAssessment";
import { useForm } from "react-hook-form";
import dynamic from "next/dynamic";

const MyPhone = dynamic(
  () => import("./../../../UI/phone/MyPhone").then((mod) => mod.MyPhone),
  {
    ssr: false,
  }
);

export function Assessment() {
  const { colors } = useColors();
  const { t } = useTranslation("common");
  const { sendAssessment, processing, error } = useAssessment();

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      description: "",
      acceptTerms: false,
    },
  });

  // @ts-ignore
  return (
    <form
      style={{ backgroundColor: colors.secondaryBackground }}
      onSubmit={handleSubmit(async (data) => {
        await sendAssessment(data, reset);
      })}
    >
      <Container noHeight>
        <Grid>
          <Grid.Col span={12} className="py-6">
            <HandWritingH1 size={60}>{t("free-assessment")}</HandWritingH1>
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 6 }} offset={{ lg: 3 }}>
            <div>
              <Grid>
                <Grid.Col span={12} offset={0}>
                  <MyH1 size={26} className="text-center font-bold">
                    {t("assessentDescribeH1")}
                  </MyH1>
                  <Text size="lg" className="text-center py-4">
                    {t("assessmentPrivacy")}
                  </Text>
                </Grid.Col>
                <Grid.Col span={12} offset={0}>
                  <TextInput
                    error={errors.name?.message}
                    withAsterisk
                    type="text"
                    label={t("name")}
                    // @ts-ignore
                    placeholder={t("name")}
                    styles={{
                      input: {
                        height: 50,
                      },
                    }}
                    {...register("name", {
                      // @ts-ignore
                      required: t("login.nameError"),
                      minLength: {
                        value: 3,
                        message: t("login.nameError"),
                      },
                      maxLength: {
                        value: 99,
                        message: t("login.nameError"),
                      },
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, lg: 6 }} offset={0}>
                  <TextInput
                    error={errors.email?.message}
                    withAsterisk
                    type="text"
                    label={t("email")}
                    // @ts-ignore
                    placeholder={t("email")}
                    styles={{
                      label: {
                        textTransform: "capitalize",
                      },
                      input: {
                        height: 50,
                      },
                    }}
                    {...register("email", {
                      // @ts-ignore
                      required: t("login.invalidEmail"),
                      // @ts-ignore
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: t("login.invalidEmail"),
                      },
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, lg: 6 }} offset={0}>
                  <MyPhone
                    hideIcon
                    height={49}
                    // @ts-ignore
                    label={t("phone")}
                    value={watch().phone}
                    field="phone"
                    stringValue
                    required
                    setFormValue={setValue}
                  />
                </Grid.Col>
                <Grid.Col span={12} offset={0}>
                  <Textarea
                    autosize
                    minRows={4}
                    error={errors.description?.message}
                    withAsterisk
                    type="text"
                    label={t("assessment-issue")}
                    // @ts-ignore
                    placeholder={t("description")}
                    styles={{
                      input: {
                        height: 50,
                      },
                    }}
                    {...register("description", {
                      // @ts-ignore
                      // required: t("contact.description-error"),
                      // minLength: {
                      //   value: 50,
                      //   message: t("contact.description-error"),
                      // },
                    })}
                  />
                </Grid.Col>
                <Grid.Col
                  span={12}
                  className="flex justify-center pb-8 py-2 items-center"
                  offset={0}
                >
                  <div className="flex gap-2 items-center">
                    <Checkbox
                      error={errors.acceptTerms?.message}
                      label={
                        <div>
                          {t("agreeWith")}{" "}
                          <Link href="/termos-e-condicoes">
                            <Text
                              className="mb-2 font-semibold"
                              style={{ color: colors.primary }}
                            >
                              {t("privacy")}
                            </Text>
                          </Link>
                        </div>
                      }
                      {...register("acceptTerms", {
                        // @ts-ignore
                        required: t("login.acceptTermsError"),
                      })}
                    ></Checkbox>
                  </div>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  className="flex flex-col items-center justify-center pb-8 py-2 gap-4"
                  offset={0}
                >
                  <Text className="!text-red-500">{error}</Text>
                  <MyButton
                    submit
                    loading={processing}
                    disabled={processing}
                    rounded
                    onClick={() => {}}
                    title={t("assessmentSend")}
                    color={colors.secondary}
                  />
                </Grid.Col>
              </Grid>
            </div>
          </Grid.Col>
        </Grid>
      </Container>
    </form>
  );
}
