import { HeroCard } from "./HeroCard";
import { useTranslation } from "next-i18next";
import { Grid } from "@mantine/core";

export function HeroCards() {
  const { t } = useTranslation("common");
  return (
    <>
      <Grid.Col
        span={{ base: 12, md: 6, lg: 3 }}
        className="flex items-center justify-center my-8"
      >
        <HeroCard number={1} title={t("mission-title")} text={t("mission")} />
      </Grid.Col>
      <Grid.Col
        span={{ base: 12, md: 6, lg: 3 }}
        className="flex items-center justify-center my-8"
      >
        <HeroCard number={2} title={t("secret-title")} text={t("secret")} />
      </Grid.Col>
      <Grid.Col
        span={{ base: 12, md: 6, lg: 3 }}
        className="flex items-center justify-center my-8"
      >
        <HeroCard number={3} title={t("values-title")} text={t("values")} />
      </Grid.Col>
      <Grid.Col
        span={{ base: 12, md: 6, lg: 3 }}
        className="flex items-center justify-center my-8"
      >
        <HeroCard
          number={4}
          title={t("commitment-title")}
          text={t("commitment")}
        />
      </Grid.Col>
    </>
  );
}
