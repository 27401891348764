import { useRouter } from "next/router";
import { WP_CMS } from "../../constants/env";
import { PageInfo, Post } from "../../types/graphql";
import { usePaginationStore } from "../../components/config/store/usePaginationStore";

export type BlogData = {
  data: {
    posts: {
      nodes: Post[];
      pageInfo: PageInfo;
    };
  };
  extensions: object;
};

export function useBlogList() {
  const router = useRouter();
  const { after } = usePaginationStore((state) => state);

  function postListQuery(first?: number) {
    return {
      query: `query fetchAllPosts {
  posts(first: ${first ? first : 100}, after: ${
        after ? `"${after}"` : "null"
      }, where: {categoryName: "blog-${router.locale}"}) {
    pageInfo {
    hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    nodes {
      id
      excerpt
      slug
      author {
        node {
        slug
          avatar {
            url
          }
          name
          id
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
      modified
      postId
      title
    }

  }
}`,
    };
  }

  async function fetchPostsFromWP(first?: number): Promise<BlogData> {
    const request = await fetch(WP_CMS, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(postListQuery(first)),
    });

    return await request.json();
  }

  return {
    fetchPostsFromWP,
  };
}
