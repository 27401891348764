import { useColors } from "../../../../hooks/colors/useColors";
import { Text } from "@mantine/core";
import { HandWritingH1 } from "../../../UI/hand-writing/HandWritingH1";
import { MyH1 } from "../../../UI/my-h1/MyH1";

type InputProps = {
  number: number;
  title: string;
  text: string;
};

export function HeroCard(props: InputProps) {
  const { number, text, title } = props;
  const { colors } = useColors();

  return (
    <div
      className="rounded-lg py-0 px-4 flex justify-center"
      style={{ backgroundColor: colors.primary, width: "100%" }}
    >
      <div className="flex items-center gap-6">
        <div className="mt-4">
          <HandWritingH1 size={80} color="white">
            {number}
          </HandWritingH1>
        </div>
        <div
          className="p-2"
          style={{ borderLeft: "2px solid", borderLeftColor: colors.white }}
        >
          <MyH1 color={colors.secondary} className="font-bold ml-3" size={27}>
            {title}
          </MyH1>
          <Text color="white" size="md">
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Text>
        </div>
      </div>
    </div>
  );
}
