import { useColors } from "../../../../hooks/colors/useColors";
import { Container } from "../../../UI/container/Container";
import { Grid } from "@mantine/core";
import { HandWritingH1 } from "../../../UI/hand-writing/HandWritingH1";
import { SpecialtyCard } from "./SpecialtyCard";
import { mobile } from "../../../../constants/env";
import { useTranslation } from "next-i18next";

export function Specialties() {
  const { colors } = useColors();
  const { t } = useTranslation("common");

  return (
    <div
      style={{
        backgroundColor: colors.background,
        // width: "100vw",
        overflow: "hidden",
        paddingBottom: 24,
      }}
    >
      <Container noHeight>
        <Grid>
          <Grid.Col span={12} className="flex items-center justify-center py-6">
            <HandWritingH1 size={mobile ? 40 : 60}>
              {t("specialties-title")}
            </HandWritingH1>
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, md: 6, lg: 4 }}
            className="flex justify-center"
          >
            <SpecialtyCard
              href="/especialidades/psiquiatria"
              icon="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/psiquiatria.png?alt=media&token=1b4e3e2f-8005-428c-8450-a633b8ac0761"
              title={t("specialties.psychiatry")}
              description={t("psychiatry-short-description")}
              image="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/especialidades%2Fpsiquiatria.png?alt=media&token=23f668e4-3ba2-46b7-9d47-8b4a24ef0360"
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, md: 6, lg: 4 }}
            className="flex justify-center"
          >
            <SpecialtyCard
              href="/especialidades/Psicoterapia-Analitica"
              icon="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/Psicoterapia.png?alt=media&token=c5e15ce7-8d83-4f53-8737-2641bd48d5d6"
              title={t("psychotherapy")}
              description={t("psychotherapy-short-description")}
              image="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/especialidades%2Fpsicoterapia.png?alt=media&token=c17d779f-61db-4d1a-a504-bb73deb5c65b"
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, md: 6, lg: 4 }}
            className="flex justify-center"
          >
            <SpecialtyCard
              href="/especialidades/pedopsiquiatria"
              iconWidth={120}
              icon="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/PedoPsicoterapia.png?alt=media&token=43c02fe3-191e-476a-a5a7-d606af7a02ff"
              title={t("specialties.pedopsychiatry")}
              description={t("pedopsychiatry-description")}
              image="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/especialidades%2Fpedopsiquiatria.png?alt=media&token=34f31bf6-3d5e-48aa-8ffb-b30963484e3a"
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, md: 6, lg: 4 }}
            className="flex justify-center"
          >
            <SpecialtyCard
              href="/especialidades/Terapia-de-Casal"
              iconWidth={120}
              icon="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/terapia-casal.png?alt=media&token=18e35646-ff39-4534-a6b9-245e3d2ab72d"
              title={t("specialties.couple-therapy")}
              description={t("couple-therapy-short-description")}
              image="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/especialidades%2Fterapia_casal.png?alt=media&token=8cd60a1d-7d1c-45b7-bd2e-f82ed399e971"
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, md: 6, lg: 4 }}
            className="flex justify-center"
          >
            <SpecialtyCard
              href="/especialidades/medicina-do-sono-em-lisboa"
              icon="https://firebasestorage.googleapis.com/v0/b/psi-works-backoffice.appspot.com/o/specialties%2FMedicina%20do%20Sono%2Fsleepico.png?alt=media&token=415c8c5d-7058-4dee-aa06-29fd99edbb67"
              title={t("specialties.sleep-therapy")}
              description={t("sleep-short-description")}
              image="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/especialidades%2Fneurologia.png?alt=media&token=c53ac71c-9ec2-4697-a905-935c7e3d4d36"
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, md: 6, lg: 4 }}
            className="flex justify-center"
          >
            <SpecialtyCard
              href="/especialidades/reumatologia"
              icon="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/Reumatologia.png?alt=media&token=36b0113d-8af2-4546-98e5-0296c6e49acb"
              title={t("specialties.rheumathology")}
              description={t("rheumathology-short-description")}
              image="https://firebasestorage.googleapis.com/v0/b/psi-works-test.appspot.com/o/especialidades%2Freumatologia.png?alt=media&token=a18c2dbf-b1e5-4562-9bba-882b4b8b4783"
            />
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
}
